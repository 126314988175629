/*  
================================================================
LOADING
================================================================  
*/


/*  
================================================================
UTILITY CLASSES
================================================================  
*/


.xs-visuallyhidden { 
  position: absolute; 
  overflow: hidden; 
  clip: rect(0 0 0 0); 
  height: 1px; width: 1px; 
  margin: -1px; padding: 0; border: 0; 
}

.xs-hide {
   display: none;
}

.xs-show {
   display: inline-block;
}


