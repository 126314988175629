.button-container{
    max-width: 350px;
    display: inline-block;
}

button, a.button {
    display: inline-block;
    border: 1px solid black;
    padding: 0 2rem 0;
    margin: 0;
    text-decoration: none;
    background: transparent;
    color: #000;
    font-family: "New Rail Alphabet", arial, sans-serif;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, 
                color 250ms ease-in-out, 
                transform 150ms ease;
                
    -webkit-appearance: none;
    -moz-appearance: none;
}


input[type=submit]{
    cursor: pointer;
}

button:hover, a.button:hover, .lg-button:hover,
button:focus, a.button:focus, input[type=submit]:hover {
    background: black;
    color: $yellow;
    transition: background 250ms ease-in-out, 
                transform 150ms ease;
}

button:focus, a.button:focus{
    outline: none;
    border: 1px solid black;
    background: black;
    color:white;
}

.bg-yellow{
    button:hover,
    button:focus {
        background: black;
        color:$yellow;
    transition: background 250ms ease-in-out,  
                color 150ms ease;
    }
}

button:active,  a.button:active {
    background: rgb(20,20,20);
}

.header-add-name-button:hover,  .header-add-name-button:hover {
    background: $yellow;
    color: black;
}


.lg-button, a.lg-button{
    width: 100%;
    height:46px;
    border-radius: 23px;
    line-height:46px;
}

.sm-button, a.sm-button{
    height:36px;
    line-height:36px;
    border-radius: 18px;
    font-size: .875rem;
}

.xs-button, a.xs-button{
    height:28px;
    line-height:28px;
    border-radius: 14px;
    font-size: 12px;
}

.icon-button, a.icon-button{
    width:36px;
    padding: 0;
    border-radius: 18px;
    img{
        width: 100%;
    }
}

.icon-button:hover, a.icon-button:hover{
    background: $yellow;
}


/*  
================================================================
DROPDOWN BUTTON
================================================================  
*/

.dropdown-button-group{
    position: relative;
    button{
        border-radius: 18px;
        display: inline-block;
        border: 2px solid  $lightBlack;
        //height: 36px;
        font-size: 1.2em;
        padding: 8px 20px 5px;
        background-color: white;
        z-index: 1;
        &:hover, &:focus{
            background-color: $lightBlack;
            color: white;
            .down-angle{
                border-color: white  transparent transparent transparent;
            }
        }
    }
    .dropdown-button-content{
        border: 2px solid black;
        border-radius: 5px;
        top: 40px;
        right: 0px;
        position: absolute;
        width: 150px;
        font-size: 1.2em;
        background: white;
        display: none;
        z-index: 0;
        transition: all 300ms ease-in-out;
        a{
            padding: 5px 10px 3px;
            display: block;
            border-bottom: 2px solid  $lightBlack;
            &:hover{
                background-color: $lightBlack;
                color: white;
                .tick{
                    border-color: white;
                }
            }
        }
        li:last-child{
            a{
                border-bottom: none;
            }
        }
    }
}

.dropdown-button-group.active{
    .dropdown-button-content{
        display: block;
    }
}

.down-angle{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 5.5px 0 5.5px;
    border-color:  $lightBlack transparent transparent transparent;
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px;
}

.tick{
    width: 18px;
    height: 9px;
    border-left: 2px solid  $lightBlack;
    border-bottom: 2px solid  $lightBlack;
    transform: rotate(-45deg);
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    left: 9px;
    top: 9px;
}