
@font-face {
  font-family: 'New Rail Alphabet';
  src: url('webfonts/NewRailAlphabet-LightWEB.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    /*font-display:swap;*/
}


@font-face {
  font-family: 'New Rail Alphabet';
  src: url('webfonts/NewRailAlphabet-BoldWEB.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    /*font-display:swap;*/
}


@font-face {
  font-family: 'Dot Mono';
  src: url('webfonts/ac-mono-v2-webfont.woff2') format('woff2'), 
      url('webfonts/ac-mono-v2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    /*font-display:swap;*/
}


body{
    font-family: 'New Rail Alphabet', sans-serif;
    line-height: 1.4;
}


::selection { background: yellow; }
::-moz-selection { background: yellow; }
::-webkit-selection { background: yellow; }


/*  
================================================================
LINK STYLES
================================================================  
*/

a, a:link, a:visited{
    color: black;
    text-decoration: none;
 }

a:hover{
    color: #FB4828;
 }

a:active{
    color: #FB4828;
 }

.read-more:before{
    content: "> ";
}

.read-more:hover, .read-more:active{
    text-decoration: underline;
}

p{
   a, a:link, a:visited{
        color: #4A90E2
    }   
    a:hover{
    color: #FB4828;
    }

    a:active{
        color: #FB4828;
     }
    
}

/*  
================================================================
HEADINGS
================================================================  
*/

h1{
    font-weight: bold;
    font-size:1.75rem;
    line-height: 1.625rem;
}

h2.section-title, .body-text h2{
    font-weight: bold;
    font-size: 1.125rem;
    padding-bottom: 1.125rem;
}

h3.footer-title{
    font-weight: bold;
    padding-bottom: .5rem;
    border-bottom: 1px solid black;
}


/*  
================================================================
FONT SIZES
================================================================  
*/

.large-text{
    font-size:2rem;
}

.medium-text, .body-text{
    font-size:1.5rem;
}

.small-text, .site-footer{
    font-size: 1rem;
}


/*  
================================================================
PARAGRAPHS
================================================================  
*/

.introduction p, .introduction address, .large-text{
    font-size: 2rem;
    padding-bottom: 2rem;
}

.body-text p{
    font-size: 1.5rem;
    padding-bottom: 1.5rem;
}

.site-footer p{
    padding-bottom: 1.5rem;
}

.small-text p{
    font-size: 1rem;
    padding-bottom: 1rem;
}

.page-section p:last-child{
    padding-bottom: 0;
}

address{
    font-style: normal;
    font-size:1em;
}


.red-text{
    color: orangered;
}