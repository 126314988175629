/*  
================================================================
Images
================================================================  
*/


img{
    max-width:100%;
    display: block;
}


.full-width-responsive{
    position: relative;
    width:100%;
    padding-bottom: 100%;
    display: block;
    .cover{
        position: absolute;
        width:100%;
        height:100%;
        object-fit: cover;
        object-position: left;
        font-family: 'object-fit: cover;';
    }
}