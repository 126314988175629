/*  
================================================================
MD GRID
================================================================  
*/


  $name: nth($lg, 1);
  $size: nth($lg, 2);

  @media only screen and (min-width: $size) {

    .col-#{$name} {
      @include flexboxgrid-sass-col-common-md;
      @include flex-basis(auto);
    }
    @for $i from 1 through $flexboxgrid-grid-columns {
      .col-#{$name}-#{$i} {
        @include flexboxgrid-sass-col-common-md;
        @include flex-basis(100% / $flexboxgrid-grid-columns * $i);
        width: 100% / $flexboxgrid-grid-columns * $i;
      }
    }
    @for $i from 0 through $flexboxgrid-grid-columns {
      .col-#{$name}-offset-#{$i} {
        @include flexboxgrid-sass-col-common-md;
        @if $i == 0 {
          margin-left: 0;
        } @else {
          margin-left: 100% / $flexboxgrid-grid-columns * $i;
        }
      }
    }
    .col-#{$name} {
      @include flex-grow(1);
      @include flex-basis(0);
      max-width: 100%;
    }
    .start-#{$name} {
      @include justify-content(flex-start);
      text-align: left;
    }

    .center-#{$name} {
      @include justify-content(center);
      text-align: center;
    }

    .end-#{$name} {
      @include justify-content(flex-end);
      text-align: right;
    }

    .top-#{$name} {
      @include align-items(flex-start);
    }

    .middle-#{$name} {
      @include align-items(center);
    }

    .bottom-#{$name} {
      @include align-items(flex-end);
    }

    .around-#{$name} {
      @include justify-content(space-around);
    }

    .between-#{$name} {
      @include justify-content(space-between);
    }

    .first-#{$name} {
      order: -1;
    }

    .last-#{$name} {
      order: 1;
    }


/*  
================================================================
UTILITY CLASSES
================================================================  
*/


.#{$name}-visuallyhidden { 
  position: absolute; 
  overflow: hidden; 
  clip: rect(0 0 0 0); 
  height: 1px; width: 1px; 
  margin: -1px; padding: 0; border: 0; 
}

.#{$name}-visible { 
  position: inherit; 
  overflow: visible; 
  clip: auto; 
  height: auto; width: auto; 
}

.#{$name}-hide {
   display: none;
}

.#{$name}-show {
   display: inline-block;
}



/*  
================================================================
DESKTOP MENU
================================================================  

button.menu-button{
    display: none;
}

.main-menu{
    position: static;
    top:auto;
    right:auto;
    width:auto;
    height:auto;
    overflow-y: scroll;
    background: transparent;
    padding-top: 0;
    padding-bottom: 0;
    z-index: 30;
    transition: right 0ms ease-in-out;
}

.main-menu a{
    width:100%;
    display: block;
    font-size: 1.5rem;
    line-height: 1.3;
    padding: 0;
    border-bottom: none;
}

.main-menu li:last-child a{
    border-bottom: none;
    margin-bottom: 0;
}
*/



/*  
================================================================
LAYOUT
================================================================  
*/

.main{
    padding-top: 120px;
}

.lg-no-pad{
    padding-top: 0;
}



/*  
================================================================
FOOTER
================================================================  
*/

.site-footer li{
    padding: 0; 
    border-bottom: none;
}



/*  
================================================================
FORM OVERLAY
================================================================  
*/


.form-overlay{
    width:60vw;
}






} /* End Media Query */


