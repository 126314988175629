/*  
================================================================
FORM ELEMENTS
================================================================  
*/

#name-submission-form:focus{
    background: pink;
}

.form-intro{
    padding-bottom: 1.5rem;
}

.form{
    font-size:1.125rem;
    padding-top: 1.5rem;
}

input, textarea{
    background: transparent;
    height:46px;
    border: 1px solid black;
    width:100%;
    padding: 2px 1rem 0;
    font-size: 1rem;
    font-family: "New Rail Alphabet", sans-serif;
    margin-bottom:1.5rem;
}


textarea{
    line-height: 1.5;
    padding-top: 10px;
    min-height:200px;
}

.keyboard-focus{
    input:focus, textarea:focus, label:focus{
        outline: 3px solid orange;
    }
}

.form fieldset{
    padding-bottom: 1rem;
}

.form h3.fieldset-title, label.fieldset-title{
    border-top:2px solid black;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 1.5rem;
    display: block;
}

.form input, .form textarea{
    font-size: 1.125rem;
    max-width:500px;
    vertical-align: baseline;
}

.form .small-text{
    max-width: 500px;
}

#ad_day, #ad_month, #ad_year{
    width:31.5%;
    text-align: left;
    max-width:158px;
}

#ad_day, #ad_month{
    margin-right:1.3%;
}

//remove google logo
.pac-logo:after{display:none;}



/*  
================================================================
Mailinglist Signup
================================================================  
*/


.sign-up-button-group{
    width:100%;
    max-width:350px;
    border: 1px solid black;
    position: relative;
    height: 46px;
    margin: 1em 0 0;
    input[type=email]{
        position: absolute;
        top:0;
        left:0;
        border: none;
        width:90%;
    }
    button{
        width:46px;
        padding:0;
        border:0;
        border-left:1px solid black;
        height:45px;
        position: absolute;
        right: 0;
    }
    button:hover, button:active{
        background: black;
        color:$yellow;
    }
}





/*  
================================================================
RADIO BUTTONS
================================================================  
*/

.radio-wrapper{
    margin-bottom: .75rem;
    &:focus-within{
        outline: none;
        outline: 3px solid orange;

    }
}

.radio-wrapper label{display:block;}

.radio-wrapper input[type=radio] {
  position: absolute; 
  overflow: hidden; 
  clip: rect(0 0 0 0); 
  height: 1px; width: 1px; 
  margin: -1px; padding: 0; border: 0; 
}

.radio-wrapper label{
    cursor: pointer;
    position: relative; 
    border:0px solid black;
    width:40px;
    height:40px;
    line-height:40px;
    text-align:center;
    display:inline-block;
    border-radius:50%;
    border:1px solid black;
    padding-left: 40px;
        .label{
            padding-left: 1rem;
            width:300px;
            text-align: left;
            display: inline-block;
        }
    }

.radio-wrapper label:hover{
    background-color: rgba( $yellow,  .4 );
}

input[type=radio]:disabled+ label, 
input[type=radio]:disabled+ label:hover{
    border-color:#C2CAD4;
    color:#C2CAD4;
    cursor:default;
    background-color: #fff;
}

.radio-wrapper div{display: inline-block;margin-right:.5em;}

.radio-wrapper label::before {
 /* content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  border:2px solid black;  
 position: absolute;*/

}

.radio-wrapper input[type=radio]:checked + label {
    content: "";
    border:1px solid #000; 
    background-color:$yellow;
    opacity: 1;
}

.black-button{
    background:black;
    color:white;
    cursor: pointer;
}

.black-button:hover, .black-button:active{
    background: $yellow;
    color:black;
}

/*  
================================================================
EMAIL CHECKBOX
================================================================  
*/

.small-checkbox-wrapper{
    margin-top:.75rem;
    margin-bottom: .75rem;
    font-size: 1rem;
}

.small-checkbox-wrapperlabel{display:block;}

.small-checkbox-wrapper input[type=checkbox] {
  position: absolute; 
  overflow: hidden; 
  clip: rect(0 0 0 0); 
  height: 1px; width: 1px; 
  margin: -1px; padding: 0; border: 0; 
}

.small-checkbox-wrapper label{
    cursor: pointer;
    position: relative; 
    border:0px solid black;
    width:20px;
    height:20px;
    line-height:20px;
    text-align:center;
    display:inline-block;
    border-radius:0%;
    border:1px solid black;
    padding-left: 20px;
        .label{
            padding-left: .5rem;
            width:350px;
            text-align: left;
            display: inline-block;
        }
    }

.small-checkbox-wrapper label:hover{
    background-color: rgba( $yellow,  .4 );
}

input[type=checkbox]:disabled+ label, 
input[type=checkbox]:disabled+ label:hover{
    border-color:#C2CAD4;
    color:#C2CAD4;
    cursor:default;
    background-color: #fff;
}

.small-checkbox-wrapper div{display: inline-block;margin-right:.5em;}

.small-checkbox-wrapper label::before {
 /* content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  border:2px solid black;  
 position: absolute;*/

}

.small-checkbox-wrapper input[type=checkbox]:checked + label {
    content: "";
    border:1px solid #000; 
    background-color:$yellow;
}




/*  
================================================================
SHORT FORM
================================================================  
*/

.form-short{
    fieldset{
        margin-bottom:1rem;
    }
    h3.fieldset-title, label.fieldset-title{
        border:0;
        font-size: 1rem;
        margin-bottom: 1rem;
        padding: 0;
        font-weight: bold;
    }
    .radio-wrapper{
        display: inline-block;
        padding:0 2px;
        width:40%;
        min-width:250px;
    }
    .radio-wrapper label{
        font-size:1rem;
    cursor: pointer;
    position: relative; 
    border:0px solid black;
    height:35px;
    width:100%;
    line-height:35px;
    text-align:center;
    display:inline-block;
    border-radius:0%;
    border:1px solid black;
    padding: 0px 1rem;
        .label{
            width:auto;
            text-align: center; 
            display: inline-block;
        }
    }
    input{
        max-width:612px;
        height:35px;
        font-size:1rem;
    }
}



/*  
================================================================
CUSTOM SELECT
================================================================  
*/

select {
    background: transparent;
    text-align:left;
    text-align-last:left;
    color:#888;
    height:46px;
    border: 1px solid black;
    width:100%;
    padding: 2px 1rem 0 ;
    font-size: 1.125rem;
    font-family: "New Rail Alphabet", sans-serif;
    margin:0;
    margin-top:-1px;
    margin-bottom:1.5rem;
    outline: none;
    border-radius: 0px;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;       /* Remove default arrow */
//   background-image: url(...);   /* Add custom arrow */
}

select:focus{
    outline: 3px solid orange;

}