/*  
================================================================
Variables and Mixins
================================================================  
*/

@import "../mixins/_flex";



// Set the gutter between columns.
$flexboxgrid-gutter-width: 20px !default;

// Set margin for the container sides.
$flexboxgrid-outer-margin: 20px !default;

// Set gutters
$gutter-compensation: $flexboxgrid-gutter-width * .5 * -1;
$half-gutter-width: $flexboxgrid-gutter-width * .5;



@mixin flexboxgrid-sass-col-common {
  box-sizing: border-box;

  // split @include flex(0, 0, auto) into individual props
  @include flex-grow(0);
  @include flex-shrink(0);

  // we leave @include flex-basis(auto) out of common because
  // in some spots we need it and some we dont
  // more why here: https://github.com/kristoferjoseph/flexboxgrid/issues/126

  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
}


/*  
================================================================
GRID
================================================================  
*/

.wrapper {
  box-sizing: border-box;
  max-width: $flexboxgrid-max-width;
  margin: 0 auto;
  padding:0 $flexboxgrid-outer-margin 0;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: $flexboxgrid-outer-margin;
  padding-left: $flexboxgrid-outer-margin;
}

.row {
  box-sizing: border-box;
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include flex-wrap(wrap);
  margin-right: $gutter-compensation;
  margin-left: $gutter-compensation;
}

.grid{
  display: flex;
  flex-wrap: wrap;
  margin-right: $gutter-compensation;
  margin-left: $gutter-compensation;
}

.row.reverse {
  @include flex-direction(row-reverse);
}

.col.reverse {
  @include flex-direction(column-reverse);
}




/*  
================================================================
XS–GRID
================================================================  
*/



$name: xs;

.col-#{$name} {
  @include flexboxgrid-sass-col-common;
  @include flex-basis(auto);
}
@for $i from 1 through $flexboxgrid-grid-columns {
  .col-#{$name}-#{$i} {
    @include flexboxgrid-sass-col-common;
    @include flex-basis(100% / $flexboxgrid-grid-columns * $i);
    width: 100% / $flexboxgrid-grid-columns * $i;
  }
}
@for $i from 0 through $flexboxgrid-grid-columns {
  .col-#{$name}-offset-#{$i} {
    @include flexboxgrid-sass-col-common;
    @if $i == 0 {
      margin-left: 0;
    } @else {
      margin-left: 100% / $flexboxgrid-grid-columns * $i;
    }
  }
}
.col-#{$name} {
  @include flex-grow(1);
  @include flex-basis(0);
  max-width: 100%;
}
.start-#{$name} {
  @include justify-content(flex-start);
  text-align: left;
}

.center-#{$name} {
  @include justify-content(center);
  text-align: center;
}

.end-#{$name} {
  @include justify-content(flex-end);
  text-align: right;
}

.top-#{$name} {
  @include align-items(flex-start);
}

.middle-#{$name} {
  @include align-items(center);
}

.bottom-#{$name} {
  @include align-items(flex-end);
}

.around-#{$name} {
  @include justify-content(space-around);
}

.between-#{$name} {
  @include justify-content(space-between);
}

.first-#{$name} {
  order: -1;
}

.last-#{$name} {
  order: 1;
}

